import { jwtDecode } from 'jwt-decode';

/**
 * Middleware to check if the user is authenticated
 * Very basic auth middleware, it only checks if the token is valid and not expired
 * 
 * Unauthenticated users can access:
 * - /:id - Voir une invitation
 * - /:id/decliner - Décliner une invitation
 * - /connexion - Authentification
 * - /connexion/linkedin - Authentification via LinkedIn
 * - /connexion/verification - Vérification du code de vérification
 * 
 * Authenticated users can access:
 * - / - Dashboard
 * - /:id/postuler - Postuler à une invitation
 * 
 * @param to 
 * @param from 
 */
export default defineNuxtRouteMiddleware((to, from) => {
  const to_path = to.matched[0]?.path || to.path;
  const from_path = from.matched[0]?.path || from.path;

  if (publicPaths.includes(to_path)) {
    return;
  }

  const token = useCookie(USER_TOKEN_KEY);

  // Check if the token
  try {
    // If the user is not authenticated, redirect to the login page
    if (!token.value) {
      throw new Error('Token not found');
    }

    const decodedToken = jwtDecode(token.value);

    // Check if the token is expired
    if (decodedToken.exp && decodedToken.exp < Date.now() / 1000) {
      // Remove the token from the cookies
      token.value = null;

      throw new Error('Token expired');
    }

    // If the user is authenticated, redirect to the dashboard
    if (authPaths.includes(to_path)) {
      return navigateTo('/');
    }
  } catch {
    // If the user comes from /:id(), save the id in cookies to redirect to it after login
    if(from_path === invitationPath) {
      const invitationId = useCookie(INVITATION_ID_KEY);
      invitationId.value = to.params.id as string;
    }

    return navigateTo('/connexion');
  }
});

// Invitation path
const invitationPath = '/:id()';

// Auth paths
const authPaths = [
  '/connexion', 
  '/connexion/linkedin', 
  '/connexion/verification'
];

// Paths that are public
const publicPaths = [
  invitationPath,
  '/:id()/decliner', 
  ...authPaths
];

// Paths that are private
const _ = [
  '/',
  '/postuler/:id()',
  '/postuler/:id()/infos',
  '/postuler/:id()/cv',
  '/postuler/:id()/dispo',
  '/postuler/:id()/remuneration',
  '/postuler/:id()/summary',
  '/jobs/:id()',
];